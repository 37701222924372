import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Logo from "../../../images/reference/synthon/logosynthon.png"
import Top from "../../../images/reference/synthon/top.jpg"
import Header from "../../../images/reference/synthon/header-marketing.png"
import Lucie from "../../../images/reference/synthon/reaction-marketing.png"

class Synthon extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(74,0,48,0.9),rgba(0,0,0,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>
            Individuální školení Facebook Ads pro Synthon | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//synthon.com/"
          heading="Individuální školení Facebook Ads pro Synthon"
          odstavec="Q1 2016"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Společnost Synthon byla založena v roce 1991 v Nizozemsku a zaměřuje se na dodávky cenově dostupných léků prostřednictvím inovací. Její česká pobočka v Blansku se stala základnou pro výrobu aktivních léčivých substancí."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p className="text-center">
                Připravit na míru školení využití Facebook reklamy primárně pro
                získávání nových zaměstnanců. Zaškolit interní tým jak v
                teoretické, tak praktické rovině.
              </p>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <ul>
                <li>
                  nastavení Facebook stránky, reklamního účtu a Business
                  Manageru
                </li>
                <li>vytvoření vzorové struktury kampaní</li>
                <li>identifikace cílů a cílových skupin</li>
                <li>praktické školení</li>
                <li>zaučení pro efektivní správu kampaní přes Power Editor</li>
              </ul>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Průběh spolupráce</TitleH2>
              <p>
                Jen v Blansku pracuje pro společnost Synthon nyní více než 200
                zaměstnanců a počet neustále roste. Její HR oddělení proto hledá
                další cesty, jak oslovit nové relevantní zájemce o volné
                pracovní pozice.
              </p>
              <p>
                Náš lektor Jan Koudela připravil pro tříčlenný tým teoretickou
                prezentaci o základech komunikace na sociálních sítích a zároveň
                praktický workshop, jak pracovat s vlastní Facebook stránkou,
                vytvářet placené příspěvky a vyhodnocovat jejich úspěšnost.
              </p>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Zhodnocení zadavatele</TitleH2>
              <p>
                Oslovil nás přístup společnosti igloonet: „Naučíme vás, abyste
                si poradili sami“. Tato otevřenost, spolu s profesionálním
                přístupem a podporou nám zcela vyhovuje. První náborová kampaň
                následně předčila naše očekávání.
              </p>
            </PaddingWrapper>
          </HalfWidthBlock>

          <Medailon
            wide
            image={Lucie}
            alt=""
            text="Otevřenost společnosti igloonet spolu s profesionálním přístupem a podporou nám zcela vyhovuje."
            name="Lucie Loulová"
            position="Head of HR"
          />
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Synthon
